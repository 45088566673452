<template>
  <div class="d-flex flex-column">
    <h1 class="text-center" style="color: #072878">{{ 'Bienvenido' }}</h1>
    <div class="d-flex justify-content-center">
      <img
        class="img-fluid"
        src="https://firebasestorage.googleapis.com/v0/b/el-bicho-de-los-dibujos.appspot.com/o/logo.png?alt=media&token=3d2eec8e-b941-4f2a-aaeb-de85f8e5dfaa"
        alt="logo"
        width="900"
      />
    </div>
  </div>
</template>

<script setup lang="ts"></script>
